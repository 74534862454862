import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import IMAGES from "../../assets";
import { CONSTANTS } from "../../utils/Constants";
import Button from "../../components/Button/Button";
import { UserContext } from "../../context/UserContext";
import toast from "react-hot-toast";

const Login = () => {
  const { width } = useWindowDimensions();
  const { setIsAuthenticated } = React.useContext(UserContext);
  const [login_code, setlogin_code] = React.useState("");

  const letsLogin = (code: string) => {
    const LOGINCODES = ["HK5J38", "JI3Q97", "RB4H26"];

    if (LOGINCODES.includes(code)) {
      localStorage.setItem("isAuth", "true");
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      toast.error(CONSTANTS.loginError, {
        position: "top-center",
        duration: 1000,
        className: "danger",
      });
    }
  };

  return (
    <PageWrapper
      title="Login"
      style={{
        justifyContent: "center",
        display: 'flex',
        flexDirection: 'column',
        background:
          "linear-gradient(to right bottom, #e4f2ec, #c5f0db, #a7ecc6, #8be7ae, #71e293, #6be398, #64e49d, #5de5a2, #78ebc4, #9defdd, #c6f1ec, #f0f2f2)",
      }}
    >
      <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ fontSize: '30px', color: 'white', fontWeight: 'bold' }}>Big ideas coming soon...</div>
        <div><img src={IMAGES.rocket} height={30} width={30} /></div>
      </div>
      <div
        className="font-poppins flex flex-col justify-center items-center rounded-10 shadow-lg gap-10 bg-white min-h-fit py-10 rounded-md"
        style={{
          width:
            window.innerWidth <= 768
              ? width * 0.8
              : window.innerWidth > 1260
                ? width * 0.3
                : width * 0.4,
        }}
      >

        <div className="flex flex-col justify-center items-center gap-5">
          <img
            className="img"
            width="135px"
            src={IMAGES.logo}
            alt="Sunculture"
          />

          <span className="text-2xl font-bold pt-5"> {CONSTANTS.marifa}</span>
          <span className="text-2xl font-normal text-center">
            {CONSTANTS.appTitle}
          </span>
        </div>
        <div className="flex flex-col justify-center items-center gap-5 w-full">
          <span className="text-md font-semibold mb-1 text-center">
            {CONSTANTS.enterCode}
          </span>
          <input
            type="text"
            placeholder="Enter code"
            className="p-3 bg-slate-50 w-10/12 focus:outline-none focus:ring-1 rounded-5"
            value={login_code}
            onChange={(e) => setlogin_code(e.target.value)}
          />
          <Button
            title={CONSTANTS.login}
            width={window.innerWidth >= 768 ? 450 : 300}
            height={45}
            onClick={() => letsLogin(login_code)}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Login;
